
import Vue from 'vue';
import Component from 'vue-class-component';
import BadgerAccordion from 'badger-accordion';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class Accordion extends Vue {
  @Prop() title: string;

  @Prop({ default: false }) isOpen: boolean;

  @Prop() data: Record<string, any>;
  @Prop() counterGuest: number;
  @Prop({ default: false }) invited: boolean;
  @Prop({ default: '' }) icon: string;
  //   @Watch('accordion.states')
  //   handle(_states: any): any {
  //     this.accordion.calculateAllPanelsHeight();
  //   }

  accordion: any = [];

  openOne() {
    // this.accordion.closeAll();
    this.accordion[0].close(0);
  }

  mounted() {
    this.$emitter.on('updatePanels', (_val: any) => {
      const accordionDomNode = document.querySelectorAll('.js-badger-accordion');
      let myArray = Array.from(accordionDomNode);

      //this.data.pop();

      if (_val < this.data.length) {
        myArray.pop();
      }

      myArray.forEach((ele) => {
        this.accordion.push(new BadgerAccordion(ele));
      });
    });
    // // On child-item rendered initiate badger-accordion
    const accordionDomNode = document.querySelectorAll('.js-badger-accordion');

    accordionDomNode.forEach((ele) => {
      this.accordion.push(new BadgerAccordion(ele));
    });

    // this.accordion.openAll();

    // this.accordion = new BadgerAccordion(accordions);
    // this.accordion.init();
    let timer = 20;
    setInterval(() => {
      timer -= 2;
      if (this.accordion.length > 0) {
        this.accordion.forEach((ele: any) => {
          ele.calculateAllPanelsHeight();
        });
      }
      if (timer === 0) {
        clearInterval();
      }
    }, 100);
    this.accordion[0].open(0);
  }
}
