import { AccommodationDto } from '../entities/tenant/accommodation.dto';
import { GuestDto } from './guest.dto';

export enum BookingStatus {
  PENDING = 'PENDING',
  PRECHECKEDIN = 'PRECHECKEDIN',
  CONFIRMED = 'CONFIRMED',
  CONFIRMEDCHECKIN = 'CONFIRMEDCHECKIN',
  CHECKEDIN = 'CHECKEDIN',
  PENDINGCHECKOUT = 'PENDINGCHECKOUT',
  CHECKEDOUT = 'CHECKEDOUT',
}

export default class BookingDto {
  bookingReference: string;

  numberOfGuests: number;

  checkInDate: string;

  checkOutDate: string;

  guest: GuestDto[];

  accommodationId: number;

  status: number;

  accommodation?: Partial<AccommodationDto>;

  constructor() {
    this.bookingReference = '747';
    this.numberOfGuests = 1;
    this.checkInDate = '14 Jul 2020';
    this.checkOutDate = '15 Jul 2020';
    this.guest = [];
    this.accommodationId = 1;
    this.status = 1;
  }
}
