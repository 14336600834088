/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-classes-per-file
import MinorDto from '../entities/tenant/minor.dto';
import { BookingStatus } from './bookingDto';
import { HealthCheckDto } from './health-check.dto';

export class AdditionalGuestInfo {
  additionalNotes: string;

  roomNumber: string;

  dietaryRequirements: string;

  nextOfKin: string;

  nextOfKinPhone: string;

  medicalAid: number;

  medicalAidNumber: string;

  travelInsurance: string;

  bookingStatus: string;

  luggageHandling: number;

  status: number;

  bookingToken: string;

  booking: any;

  constructor() {
    this.additionalNotes = 'I do not like wood';
    this.roomNumber = '4';
    this.dietaryRequirements = 'Vegan';
    this.nextOfKin = 'Kim';
    this.nextOfKinPhone = '948 484 8484';
    this.medicalAid = 1;
    this.medicalAidNumber = '854jdfhf8';
    this.travelInsurance = 'Yes';
    this.bookingStatus = 'PENDING';
    this.luggageHandling = 1;
    this.status = 0;
    this.bookingToken = '';
  }
}
export class GuestDto {
  firstName: string;

  phone: [];

  lastName: string;

  mobileNumber: string;

  email: string;

  nationality: string;

  rsaId: string;

  passport: string;

  dateOfBirth: string;

  minor: MinorDto[];

  additionalNotes: string;

  roomNumber: string;

  dietaryRequirements: string;

  nextOfKin: string;

  nextOfKinPhone: string;

  medicalAid: number;

  medicalAidNumber: string;

  travelInsurance: string;

  bookingStatus: string;

  luggageHandling: number;

  bookingToken: string;

  booking: any;

  healthCheck?: HealthCheckDto;

  checkIn!: CheckinDto;

  status?: BookingStatus.PENDING;

  isPrimary!: boolean;

  constructor() {
    this.firstName = 'Darron';
    this.lastName = 'Reinhardt';
    this.mobileNumber = '0484 84845 849';
    this.email = 'nick@handycats.com';
    this.nationality = 'South Africa';
    this.rsaId = 'i48458484848484';
    this.passport = '599595959595';
    this.dateOfBirth = '10 Jun 1992';
    this.minor = [];
    this.status = BookingStatus.PENDING;
    this.additionalNotes = 'I do not like wood';
    this.roomNumber = '4';
    this.dietaryRequirements = 'Vegan';
    this.nextOfKin = 'Kim';
    this.nextOfKinPhone = '948 484 8484';
    this.medicalAid = 1;
    this.medicalAidNumber = '854jdfhf8';
    this.travelInsurance = 'Yes';
    this.bookingStatus = 'PENDING';
    this.luggageHandling = 1;
    this.bookingToken = '';
  }
}

// export class MinorDto {
//   firstName: string;

//   lastName: string;

//   nationality: string;

//   passportNumber: string;

//   requirements: string;

//   rsaId: string;

//   dateOfBirth: string;

//   healthCheck: HealthCheckDto;

//   constructor() {
//     this.firstName = 'Nick';
//     this.lastName = 'Bow';
//     this.nationality = 'South African';
//     this.passportNumber = '5959599595';
//     this.rsaId = '49849848484';
//     this.dateOfBirth = '10 Jun 2002';
//     this.healthCheck = {
//       fitnessLevel: '',
//       cardiovascularDisease: false,
//       hypertension: false,
//       smoking: false,
//       age: '',
//       physicalImpairments: '',
//       travelRecently: '',
//       contactRecently: false,
//       recentTest: false,
//       healthInsurance: false,
//       termsAccepted: false,
//       organisationAccepted: false,
//     } as HealthCheckDto;
//   }
// }

interface CheckinDto {
  bookingId?: number;
  checkInStatus?: string;
  checkInToken?: string;
  guestID?: number;
  signatureImage?: string;
}
